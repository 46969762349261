import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyAmVNG-0ARqJr6UN4hit9JsOCjuajYy2I0",
    authDomain: "ereignismelder-push.firebaseapp.com",
    projectId: "ereignismelder-push",
    storageBucket: "ereignismelder-push.appspot.com",
    messagingSenderId: "20511199110",
    appId: "1:20511199110:web:c4cdc4afb2b5ebfb9d6607",
    measurementId: "G-D1BGMD2E85"
};

let firebaseApp;
export let messaging;

export const tryregisterMessaging = () => {

    if ('serviceWorker' in navigator) {
        firebaseApp = initializeApp(firebaseConfig);
        messaging = getMessaging(firebaseApp);
    }
}


export const fetchToken = () => {

    return getToken(messaging, {vapidKey: ' BHw0_cfaThczKMrnE-cgPqQK4SWC6Uh06dpRIEC1LDXGitdxibSMB6kXo1rgg6m7w7PmiOvtfrbqeGbmQ9nnwcY'})
}



