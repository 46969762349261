import React, { useEffect, useRef, useState } from "react"
import {
    ListItemButton, ListItemSecondaryAction, Paper, TextField, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Add, Close, Delete, Edit, Language, TabletAndroid, Visibility, VisibilityOff } from "@mui/icons-material";
import { createPortal } from "react-dom";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { APIURL } from "../../../Views/Routes";
import {
    axiosGraphQLQuery, buildAddRequest, getNotificationEnabled, notificationObject, setNotificationObject
} from "../../Auth/AuthHandler";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { deviceDetect, isMobile, mobileModel } from "react-device-detect"
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { v4 as uuidv4 } from 'uuid';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fetchToken } from "./firebase";
import { getSession } from "../../Auth/AuthHandler";

var dockerNames = require('docker-names');

export default function NotificationDialog(props) {

    const { open, setOpen } = props
    const [devices, setDevice] = useState([])
    const portalElement = document.getElementById('portal');
    const [currentDeviceName, setCurrentDeviceName] = useState("")
    const [isTokenFound, setTokenFound] = useState(false);
    const isflutter = typeof window.flutter_inappwebview !== 'undefined'

    const loadDevices = () => {

        axiosGraphQLQuery(`
        {
          gsdevices {
            notiuuid
            deviceid
            typ
            name
          }
        }`).then(x => {
            if (x.data?.data?.gsdevices) {
                setDevice(x.data?.data?.gsdevices)

            }
        })

    }

    useEffect(() => {
        if (open) setNotificationObject({ notiuuid: localStorage.getItem("notiuuid") })
        loadDevices()
    }, [open])

    const handleClose = (e) => {
        setOpen(false)
    }

    const handleDeviceNameChanged = (e) => {
        setCurrentDeviceName(e.target.value)
    }

    const handleDeleteDevice = (deviceid) => {
        var stmnt = `
        mutation {
          deleteDObject(
            dtablename: gsdevices
            where: [{connective: BLANK, column: gsdevices_deviceid, operator: EQUAL, value: "${deviceid}"}]
          )
        }
        `
        axiosGraphQLQuery(stmnt).then(x => {
            localStorage.delete("notiuuid")
            loadDevices()
        })
    }

    const handleEditDeviceName = (deviceuuid) => {
    }


    const handleCreateNewDevice = (e) => {

        if (isflutter) {
            getPushToken()
                .then(x => loadDevices())
        } else {
            getWebpush(currentDeviceName)
                .then(x => loadDevices())
        }
    }

    useEffect(() => {
        loadDevices()
    }, [notificationObject])


    const component = open ? (<div className="portal-overlay" style={{ backdropFilter: "blur(5px)" }}>
        <Paper className="confirm-dialog" sx={{
            borderRadius: 10, border: 1, borderColor: "primary.main", minWidth: "85vw",
        }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid container justifyContent="center">
                    <Grid container item xs={11} justifyContent="center">
                        <Typography
                            variant="h4">Benachrichtigungen</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="primary" onClick={handleClose}> <Close /></IconButton>
                    </Grid>
                </Grid>

                <Divider style={{ width: "100%", marginTop: 10, marginBottom: 5 }} />
                <Grid container justify="center" alignItems="center" alignContent="center">
                    <Grid item xs={12} container justifyContent="center">
                        <Typography>
                            Liste der Geräte, die Benachrichtigungen erhalten:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center">
                        <List style={{ maxHeight: '50%', overflow: 'auto' }}>
                            {devices.map(x => <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <TabletAndroid />
                                        {x.typ === "android" ? <AndroidIcon /> : x.typ === "web" ? <Language /> :
                                            <AppleIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={x.name} />
                                </ListItemButton>
                                <ListItemSecondaryAction>
                                    {x.notiuuid === notificationObject?.notiuuid ? <IconButton color="primary"
                                        onClick={e => handleEditDeviceName(x.notiuuid)}
                                        size="large"
                                        edge="end"
                                        aria-label="delete">
                                        <Edit style={{ marginTop: 2, color: "primary" }} />
                                    </IconButton> : <></>}
                                    <IconButton onClick={e => handleDeleteDevice(x.deviceid)} size="large"
                                        edge="end" aria-label="delete">
                                        <Delete style={{ marginTop: 2, color: "red" }} />
                                    </IconButton>

                                </ListItemSecondaryAction>
                            </ListItem>)}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{ width: "100%", marginTop: 5, marginBottom: 20 }} />
                    </Grid>

                    <Grid xs={12} container justify="center" justifyContent="center">
                        <Grid style={{ marginBottom: 15 }} item xs={12} container justifyContent="center">

                            < Typography align="center">
                                {isflutter ? "Um dieses Gerät hinzuzufügen bitte das blaue '+' auswählen" : "Um dieses Gerät hinzuzufügen bitte einen Name eingeben und das blaue '+' auswählen"}
                            </Typography>:

                        </Grid>
                        {isflutter ? <></> : <TextField
                            label="Gerätename"
                            value={currentDeviceName}
                            onChange={e => setCurrentDeviceName(e.target.value)}>
                        </TextField>}

                        <IconButton
                            disabled={!isflutter && currentDeviceName === ""}
                            onClick={handleCreateNewDevice}
                            color="primary">
                            <Add color={currentDeviceName === "" ? "" : "primary"} /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </div>) : null;

    return createPortal(component, portalElement);

}

const savetokenApp = (tokenrequest) => {
    setNotificationObject({
        notiuuid: tokenrequest.notiuuid,
        name: tokenrequest.deviceName,
        type: tokenrequest.devicetype,
        token: tokenrequest.pushToken
    })

    localStorage.setItem("notiuuid", notificationObject.notiuuid)



    var stmnt = `
    mutation{
    addDTable(
        dtablename:"gsdevices",
        superDTable:DTABLE,
        sriBuildInfo:"\${email}-\${typ}",
        dataLinks:[
        {alias:"notiuuid",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"deviceid",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"email",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"typ",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"name",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        ],
        data:[
            ["notiuuid","deviceid","email","typ" ,"name"]
            ["${notificationObject.notiuuid}",
            "${notificationObject.token}",
            "${getSession().username}",
            "${notificationObject.type}",
            "${notificationObject.name}"]
        ]
    )
    }
`
    return axiosGraphQLQuery(stmnt).then(x => {
        console.log("apppush saved")
    })
}

const savetokenWeb = (currentToken) => {
    setNotificationObject({
        notiuuid: localStorage.getItem("notiuuid") ? localStorage.getItem("notiuuid") : uuidv4(),
        name: localStorage.getItem("currentDeviceName") ? localStorage.getItem("currentDeviceName") : dockerNames.getRandomName(),
        type: "web",
        token: currentToken
    })

    localStorage.setItem("notiuuid", notificationObject.notiuuid)
    localStorage.setItem("currentDeviceName", notificationObject.name)


    var stmnt = `
    mutation{
    addDTable(
        dtablename:"gsdevices",
        superDTable:DTABLE,
        sriBuildInfo:"\${email}-\${typ}",
        dataLinks:[
        {alias:"notiuuid",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"deviceid",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"email",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"typ",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        {alias:"name",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:STRING}
        ],
        data:[
            ["notiuuid","deviceid","email","typ" ,"name"]
            ["${notificationObject.notiuuid}",
            "${notificationObject.token}",
            "${getSession().username}",
            "${notificationObject.type}",
            "${notificationObject.name}"]
        ]
    )
    }
`
    return axiosGraphQLQuery(stmnt).then(x => {
        console.log("webpush saved")
    })
}

const insertNoteUUIDandTokenApp = (tokenrequest) => {

    if (tokenrequest) {

        return axiosGraphQLQuery("{gsdevices{deviceid}}")
            .then(x => {
                var devicelist = x?.data?.data?.gsdevices
                if (devicelist && devicelist?.length > 0) {
                    fetch('https://fcm.googleapis.com/fcm/send', {
                        method: 'POST',
                        headers: {
                            "Authorization": "key=AAAAXP-sgxM:APA91bGpr_e1ZDyNztb3Hqkhd9AhOVB9WH-4eysP_0WHdcCin06rDWAXoSVbik11bDfVayrnezQzcW9yunYLI4hgQqfhV2P-uZqwMeICrrz0_hnXnfsvVPb55BphQSbDr8S0Y7lO6zVX",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ registration_ids: devicelist.map(x => x.deviceid) })
                    }).then(response => response.json())
                        .then(data => {
                            console.log('Response:', data);

                            var firstflag = true
                            var wherestatus = ""
                            devicelist.forEach((devicetoken, idx) => {

                                if ("error" in data.results[idx]) {

                                    var whereclause = ` {connective:${firstflag ? "BLANK" : "OR"},
                                column:gsdevices_deviceid,
                                operator:EQUAL,
                                value:"${devicetoken.deviceid}"}`

                                    firstflag = false
                                    wherestatus = wherestatus + "\n" + whereclause

                                } else {
                                    return ""
                                }
                            })

                            if (wherestatus != "") {
                                var stmnt = `
                            mutation{ 
                                deleteDObject(dtablename:gsdevices
                                where:[${wherestatus}])
                            }`
                                axiosGraphQLQuery(stmnt)
                            }
                            return savetokenApp(tokenrequest)
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                } else {
                    return savetokenApp(tokenrequest)
                }
            }).catch(err => {

                if (err?.errors) {
                    return savetokenApp(tokenrequest)
                }
                console.log("dtablequery")
            })
    }
}


export const getWebpush = (currentDeviceName) => {
    return fetchToken().then((currentToken) => {
        if (currentToken) {

            return axiosGraphQLQuery("{gsdevices{deviceid}}")
                .then(x => {
                    var devicelist = x?.data?.data?.gsdevices
                    if (devicelist && devicelist?.length > 0) {
                        fetch('https://fcm.googleapis.com/fcm/send', {
                            method: 'POST',
                            headers: {
                                "Authorization": "key=AAAAXP-sgxM:APA91bGpr_e1ZDyNztb3Hqkhd9AhOVB9WH-4eysP_0WHdcCin06rDWAXoSVbik11bDfVayrnezQzcW9yunYLI4hgQqfhV2P-uZqwMeICrrz0_hnXnfsvVPb55BphQSbDr8S0Y7lO6zVX",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({ registration_ids: devicelist.map(x => x.deviceid) })
                        }).then(response => response.json())
                            .then(data => {
                                console.log('Response:', data);

                                var firstflag = true
                                var wherestatus = ""
                                devicelist.forEach((devicetoken, idx) => {

                                    if ("error" in data.results[idx]) {

                                        var whereclause = ` {connective:${firstflag ? "BLANK" : "OR"},
                                        column:gsdevices_deviceid,
                                        operator:EQUAL,
                                        value:"${devicetoken.deviceid}"}`

                                        firstflag = false
                                        wherestatus = wherestatus + "\n" + whereclause

                                    } else {
                                        return ""
                                    }
                                })

                                if (wherestatus != "") {
                                    var stmnt = `
                                    mutation{ 
                                        deleteDObject(dtablename:gsdevices
                                        where:[${wherestatus}])
                                    }`

                                    axiosGraphQLQuery(stmnt)

                                }

                                return savetokenWeb(currentToken)


                            })
                            .catch(error => {
                                console.error('Error:', error);
                                // Handle the error
                            });
                    } else {
                        return savetokenWeb(currentToken)
                    }

                }).catch(err => {

                    if (err?.errors) {
                        return savetokenWeb(currentToken)
                    }

                    console.log("dtablequery")
                })

        } else {
            return Promise.resolve()
        }

    })
}

export const getPushToken = () => {


    if (!window.flutter_inappwebview.callHandler) {
        console.log("_callhandler");
        return window.flutter_inappwebview._callHandler('getPushToken')
            .then(function (result) {
                console.log(JSON.stringify(result));
                return insertNoteUUIDandTokenApp(result)
            })
    } else {
        console.log("callhandler");
        return window.flutter_inappwebview.callHandler('getPushToken')
            .then(function (result) {
                console.log(JSON.stringify(result));
                return insertNoteUUIDandTokenApp(result)
            })
    }

}